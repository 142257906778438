import axios from 'axios';

// functions
import { getErrorMessageByCode } from 'env/server_errors';
import { getAccount } from 'helpers';
import { Notification } from 'helpers';

export const authAxios = axios.create();

// for error handling
authAxios.interceptors.response.use(
    (response) => {
        if (response?.data?.status && !response?.data?.isSuccess) {
            Notification.fire({ text: response?.data?.message, icon: 'error' });
            return Promise.reject(response?.data);
        } else {
            return response;
        }
    },
    (error) => {
        let ERROR_CODE = '';
        if (error?.response?.status == 401) {
            window.location.replace('/login');
        }
        if (error.message == 'Network Error') {
            Notification.fire({ text: error.message, icon: 'error' });
        } else {
            const message = getErrorMessageByCode(ERROR_CODE);
            Notification.fire({ text: error?.response?.data?.message ?? message, icon: 'error' });
        }
        return Promise.reject(error);
    }
);
authAxios.interceptors.request.use((config) => {
    const token = getAccount()?.token;
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
});
